import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";

const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    DESIGN: "tech",
    BRAND: "Brand",
    PHOTOS: "PersonalProject",
  };

  const projectsData = [
    {
      title: "Amazone-clone",
      projectInfo:
        "A replica of Amazon, designed to showcase e-commerce functionality and user experience.",
      client: "Personal Project",
      technologies: "React, CSS, Bootstrap, JavaScript, Firebase, Stripe",
      industry: "Technology",
      date: "August 14, 2024",
      url: {
        name: "amazon-frontend-clone.app",
        link: "https://new-amazon-frontend-clone.netlify.app/",
      },
      socialLinks: {
        github: "https://github.com/shhiwot/Amazon-clone.git",
        linkedin: "https://www.linkedin.com/in/your-linkedin-profile/",
      },
      thumbImage: "images/projects/amazon1pic.jpg",
      sliderImages: [
        "images/projects/mycloneamazonpic.png",
        "images/projects/amazonpic.jpg",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Evangadi Forum",
      projectInfo:
        "Connect with peers, collaborate on projects, and enhance your professional growth.",
      client: "Personal Project",
      technologies: "React, CSS, Bootstrap, JavaScript, Node.js, MySQL",
      industry: "Technology",
      date: "August 14, 2004",
      url: {
        name: "Evangadi Forum Front-End App",
        link: "https://evangadi-forum-front-end-app.vercel.app/",
      },
      socialLinks: {
        github: "https://github.com/shhiwot/Evangadi-Forum.git",
        linkedin: "https://www.linkedin.com/in/your-linkedin-profile/",
      },
      thumbImage: "images/projects/project2.jpg",
      sliderImages: [
        "images/projects/pic-project2.png",
        "images/projects/evangadilogin.png",
      ],
      categories: [filters.PHOTOS],
    },
    {
      title: "Netflix-clone",
      projectInfo:
        "A replica of Netflix, developed to showcase streaming functionalities and user experience.",
      client: "Personal Project",
      technologies: "React, CSS, Bootstrap, JavaScript, GitHub",
      industry: "Technology",
      date: "August 14, 2024",
      url: {
        name: "Netflix 2024 Clone",
        link: "https://shhiwot.github.io/Netflix--2024-/",
      },
      socialLinks: {
        github: "https://github.com/shhiwot/Netflix--2024-.git",
        linkedin: "https://www.linkedin.com/in/your-linkedin-profile/",
      },
      thumbImage: "images/projects/netflix2.png",
      sliderImages: [
        "images/projects/myneflixclone.png",
        "images/projects/netflix1.jpg",
      ],
      categories: [filters.BRAND],
    },
    {
      title: "Apple Clone",
      projectInfo:
        "This project aims to replicate the Apple website and utilizes the Fetch API to handle requests from a YouTube backend.",
      client: "Personal Project",
      technologies:
        "React, CSS, Bootstrap, YouTube API, JavaScript, Node.js, MySQL",
      industry: "Technology",
      date: "August 14, 2024",
      url: {
        name: "Apple-clone.com",
        link: "https://tubular-sable-f4d2f6.netlify.app/",
      },
      socialLinks: {
        github: "https://github.com/your-github-username/apple-clone",
        linkedin: "https://www.linkedin.com/in/your-linkedin-profile/",
      },
      thumbImage: "images/projects/apple2.jpg",
      sliderImages: [
        "images/projects/applepic.png",
        "images/projects/appleclone.png",
      ],
      categories: [filters.DESIGN, filters.PHOTOS],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setImagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
