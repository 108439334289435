import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "React Application Developer",
      desc: "Specializes in developing applications using React. Demonstrates expertise in creating dynamic and responsive user interfaces that enhance user experience.",
      icon: "fa-brands fa-react",
    },
    {
      name: "Web Design",
      desc: "As a Web Designer, I create visually appealing and user-friendly websites by crafting intuitive layouts and ensuring responsive design.",
      icon: "fas fa-desktop",
    },
    {
      name: "Node.js Developer",
      desc: "Specializes in utilizing Node.js for backend development, creating efficient and scalable server-side applications. Expertise includes implementing backend logic and integrating with various data sources and services.",
      icon: "fab fa-node-js",
    },
    {
      name: "MySQL Developer",
      desc: " Utilizes MySQL for backend development, specializing in designing and managing relational databases to support robust and scalable applications.",
      icon: "fas fa-database",
    },
    {
      name: "JavaScript Developer",
      desc: "Specializes in using JavaScript to create interactive and dynamic websites, enhancing user experiences through engaging and responsive features.",
      icon: "fab fa-js",
    },
    {
      name: "Bootstrap Developer",
      desc: " Utilizes Bootstrap to ensure cross-browser compatibility and create responsive, mobile-first websites efficiently.",
      icon: "fab fa-bootstrap",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
